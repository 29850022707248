import React from 'react'
import Style from '../../components/style/Style'

const Editor: React.FC = () => {
  const cssText = `  .editor {
    display: flex;
  }

  .editor .editor-panel {
    margin: 30px 30px;
    width: 50%;
  }

  .editor .editor-panel section {
    background-color: var(--main-color);
    color: var(--back-color);
    height: 100%;
  }
  
  .editor style.code {
    display: block;
    outline: none;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 10px 0 0 10px;
    font-family: 'Source Code Pro', monospace;
    font-size: 13px;
    color: var(--back-color);
    line-height: 1.65;
    tab-size: 2;
    white-space: pre-wrap;
    word-break: break-all;
    user-select: auto;
    caret-color: var(--back-color);
  }
`

  const htmlStr = `
  <div class="editor">
    <div class="editor-panel">
      <p>CSS</p>
      <section>
        <style class="code" contenteditable>
  .circle {
    margin: auto;
    width: 100px;
    height: 100px;
    background-color: green;
    border-radius: 50%;
  }
        </style>
    </section>
    </div>
    <div class="editor-panel">
      <p>RESULT</p>
      <section>
        <div class="circle"></div>
      </section>
    </div>
  </div>
`
  return (
    <Style
      htmlStr={htmlStr}
      cssText={cssText}
      defaultTab='CSS'
      htmlEditable={false}
      title='CodePen but by CSS Only'
    >
      <article>
        <div className='callout'>
          Ok. The title is a little dramatic. It's just a minimal CSS code
          editor. But it's made without JS code, isn't that cool?
        </div>
        <p>
          Well, I think it it. Currently on this very page, the leftside
          area(code editing and renderring area) is built on the top of this
          trick.
        </p>
        <h1>How it works</h1>
        <p>
          Basically, by <b>html style tag</b> and <code>contenteditable</code>{' '}
          attribute.
        </p>
        <pre>
          {`
<div>
  <style contenteditable></style>
  <div class="result">
    <!-- Some DOM here -->
  </div>
</div>
        `}
        </pre>
        <p>
          Just like this. Then add some fancy CSS code, I've added CSS Only Tab
          Switch feature for bonus in this site. Nothing important to write
          about.
        </p>
        <h1>It's Ugly</h1>
        <p>
          True, use style tag meant there is no code-highlighting. It's just a
          big twin of textarea. But hey, I didn't even know the style tag is
          contenteditable before, and I think that building such an editor
          without JS is fun enough.
        </p>
      </article>
    </Style>
  )
}

export default Editor
