import React from 'react'
import Style from '../../components/style/Style'
import './Random.css'

interface RandomProps {}

const Random: React.FC<RandomProps> = () => {
  const cssText = `  .random {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  
  .digits {
    display: flex;
  }
  
  .digit {
    position: relative;
    height: 15vmin;
    width: calc(15vmin / 1.5);
    background-size: 20px 30px;
    margin: calc(15vmin / 10);
  }
  
  .digit::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    mask-image: url(https://img.pub/f/digits.svg);
    -webkit-mask-image: url(https://img.pub/f/digits.svg);
    mask-size: cover;
    -webkit-mask-size: cover;
    background-color: var(--main-color);
  }
  
  .digit_1::before {
    animation: random 10ms steps(9, end) infinite;
    animation-play-state: running;
  }
  .digit_2::before {
    animation: random 11ms steps(9, end) infinite;
    animation-play-state: running;
  }
  .digit_3::before {
    animation: random 12ms steps(9, end) infinite;
    animation-play-state: running;
  }
  input:checked + .digits .digit::before {
    animation-play-state: paused;
  }
  input.random-input {
    height: 0;
    width: 0;
    border: none;
    outline: none;
    display: none;
  }
  @keyframes random {
    0% {
      -webkit-mask-position: 0 0;
    }
    100% {
      -webkit-mask-position: 100% 0;
    }
  }
  label[for='only'] {
    height: 40px;
    user-select: none;
  }
  label .button {
    cursor: pointer;
    padding: 0 30px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    min-width: 120px;
    display: inline-block;
    color: var(--main-color);
    transition: box-shadow 0.5s;
    position: relative;
    border: var(--stroke-width) solid var(--main-color);
    transition: all 0.5s;
  }
  
  label .button:hover {
    border-color: var(--high-color);
  }
  
  label .button::before {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    line-height: 40px;
    text-align: center;
    content: 'STOP';
  }
  label .button:hover::before {
    color: var(--high-color);
  }
  input:checked ~ label .button::before {
    content: 'START';
  }`

  const htmlStr = `<div class='random'>
  <input class='random-input' type='checkbox' id='only' />
  <div class='digits'>
    <div class='digit digit_1'></div>
    <div class='digit digit_2'></div>
    <div class='digit digit_3'></div>
  </div>
  <label for='only'>
    <div class='button'></div>
  </label>
</div>`
  return (
    <Style
      htmlStr={htmlStr}
      cssText={cssText}
      defaultTab='CSS'
      htmlEditable={false}
      title='Random Number but by CSS Only'
    >
      <article>
        <div className='callout'>
          Firstly let's face the truth: there is no randomness in CSS.
        </div>
        <h1>So how to simulate it?</h1>
        <p>
          Briefly, by setting a frequently changed animation of{' '}
          <code>background-image</code>.
        </p>
        <p>
          First create an image of digits, , from zero to nine. I created and
          exported it as SVG by Figma.
        </p>
        <img src='https://img.pub/s/mEm.png' alt='' />
        <p>
          Then create an element, use the image as the element's{' '}
          <code>background-image</code> or <code>mask-image</code>.
        </p>
        <pre>
          {`
.digit::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  mask-image: url(https://img.pub/f/digits.svg);
  mask-size: cover;
  background-color: var(--main-color);
}
        `}
        </pre>
        <p>
          Finally, set an animation to that emelent. Note the fast 10ms of{' '}
          <code>animation-duration</code>. The animation runs so fast that we
          barely predict or see the element's current{' '}
          <code>background-image</code>.
        </p>
        <pre>{`
.digit::before {
  animation: random 10ms steps(9, end) infinite;
}

@keyframes random {
  0% {
    mask-position: 0 0;
  }
  100% {
    mask-position: 100% 0;
  }
}
        `}</pre>
        <p>
          If more than one digit was needed, simply just duplicate the element
          and change the <code>animation-duration</code> slightly, because if
          don't all the digits would be same.
        </p>
      </article>
    </Style>
  )
}

export default Random
