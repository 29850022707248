import 'animate.css'
import './App.css'

import Random from './pages/random/Random'
import Index from './pages/index/Index'
import Editor from './pages/editor/Editor'

import {
  createHashHistory,
  Outlet,
  ReactLocation,
  Router,
} from '@tanstack/react-location'
const hashHistory = createHashHistory()
const location = new ReactLocation({
  history: hashHistory,
})

function App() {
  return (
    <Router
      location={location}
      routes={[
        { path: '/', element: <Index /> },
        {
          path: 'random',
          element: <Random />,
        },
        {
          path: 'editor',
          element: <Editor />,
        },
      ]}
    >
      <Outlet /> {/* Start rendering router matches */}
    </Router>
  )
}

export default App
