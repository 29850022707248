import React from 'react'
import Style from '../../components/style/Style'
import Logo from '../../components/logos/Brand'
import { Link } from '@tanstack/react-location'

interface Props {}

const Index: React.FC<Props> = () => {
  const cssText = `
  /* This area shows the CSS code. */
  /* You can edit here and see the results */
  
  .example {
    margin: 20px;
    padding: 20px;
    font-size: 18px;
    line-height: 2;
  }
  .example a {
    color: deeppink;
  }
  .example a:hover {
    filter: hue-rotate(-10deg);
  }
  `

  const htmlStr = `
  <!--This area shows the HTML code.-->

  <div class="example">
    This is a demonstration of the code editor area.
    <br />
    The whole code eidtor/viewer is made with CSS only.
    <br />
    You can see <a href='https://css.sh/#/editor' target="_blank">CodePen Editor but by CSS Only</a> for more information.
  </div>
  `
  return (
    <Style
      htmlStr={htmlStr}
      cssText={cssText}
      defaultTab='CSS'
      htmlEditable={false}
      title='CSS.SH'
      hideTitle
    >
      <article>
        <Logo />
        <h1>Newest</h1>
        <p>
          <Link to='random'>
            <a href='#random'>Random Number but by CSS Only</a>
          </Link>
        </p>
        <p>
          <Link to='editor'>
            <a href='#editor'>CodePen Editor but by CSS Only</a>
          </Link>
        </p>
      </article>
    </Style>
  )
}

export default Index
