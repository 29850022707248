import React from 'react'
import Theme from '../theme/Theme'
import './Settings.css'

interface SettingsProps {}

const Settings: React.FunctionComponent<SettingsProps> = () => {
  return (
    <div className={`settings-container`}>
      <div className='settings-content'>
        <h3>Theme</h3>
        <Theme />
      </div>
    </div>
  )
}

export default Settings
