import React, { useEffect } from 'react'
import Article from '../article/Article'
import Settings from '../settings/Settings'
import './Style.css'
interface StyleProps {
  htmlStr: string
  cssText: string
  htmlEditable?: boolean
  cssEditable?: boolean
  children?: React.ReactNode
  defaultTab?: 'HTML' | 'CSS'
  title: string
  hideTitle?: boolean
}

const Style: React.FC<StyleProps> = (props) => {
  const {
    htmlStr,
    cssText,
    htmlEditable = true,
    cssEditable = true,
    defaultTab = 'CSS',
    children,
    title,
    hideTitle = false,
  } = props
  useEffect(() => {
    // :has() selector polyfill
    const inputList = Array.from(document.querySelectorAll('input:checked'))
    inputList.forEach((el) => {
      const panel = document.querySelector(`section#${el.id}`)
      //@ts-ignore
      panel.style.display = 'block'
    })
  }, [])
  useEffect(() => {
    // :has() selector polyfill
    // handle click tab
    document.addEventListener('click', (e: any) => {
      if (e.target?.tagName.toUpperCase() !== 'LABEL') return
      const htmlFor = e.target?.htmlFor

      if (htmlFor) {
        const panel: HTMLElement | null = document.querySelector(
          `section#${htmlFor}`
        )

        if (panel) {
          let sibling = panel.parentNode?.firstChild

          // Loop through each sibling and push to the array
          while (sibling) {
            if (sibling.nodeType === 1 && sibling !== panel) {
              //@ts-ignore
              sibling.style.display = 'none'
            }
            sibling = sibling.nextSibling
          }
          panel.style.display = 'block'
        }
      }
    })
  }, [])
  return (
    <div>
      {/* <div className='style-mobile'>
        <Mobile
          htmlStr={htmlStr}
          cssText={cssText}
          title={title}
          defaultTab={defaultTab}
          htmlEditable={htmlEditable}
          cssEditable={cssEditable}
        >
          {children}
        </Mobile>
      </div> */}
      <div className='style-container'>
        <div className='style-editor'>
          <div className='style-text'>
            <div className='tabset'>
              <div className='tabs'>
                <input
                  type='radio'
                  name='tabset'
                  id='HTML'
                  aria-controls='HTML'
                  defaultChecked={defaultTab === 'HTML'}
                />
                <input
                  type='radio'
                  name='tabset'
                  id='CSS'
                  aria-controls='CSS'
                  defaultChecked={defaultTab === 'CSS'}
                />
                <label className='tab HTML' htmlFor='HTML' data-group='tabset'>
                  HTML
                </label>

                <label className='tab CSS' htmlFor='CSS' data-group='tabset'>
                  CSS
                </label>

                <div className='border-holder'></div>
              </div>

              <div className='tab-panels'>
                <section id='HTML' className='tab-panel'>
                  <div
                    className='code code-html readonly'
                    contentEditable={htmlEditable}
                    spellCheck='false'
                  >
                    {htmlStr}
                  </div>
                </section>
                <section id='CSS' className='tab-panel'>
                  <style
                    className='code code-css'
                    contentEditable={cssEditable}
                    spellCheck='false'
                  >
                    {cssText}
                  </style>
                </section>
              </div>
            </div>
          </div>
          <div className='style-component'>
            <div className='tabset'>
              <div className='tabs'>
                <input
                  type='radio'
                  name='tabset-result'
                  id='RESULT'
                  aria-controls='RESULT'
                  defaultChecked
                />
                <label
                  className='tab RESULT'
                  htmlFor='RESULT'
                  data-group='tabset-result'
                >
                  RESULT
                </label>
                <div className='border-holder'></div>
              </div>
              <div className='tab-panels'>
                <section
                  id='RESULT'
                  className='tab-panel'
                  dangerouslySetInnerHTML={{ __html: props.htmlStr }}
                ></section>
              </div>
            </div>
          </div>
        </div>
        <div className='style-article'>
          <div className='tabset'>
            <div className='tabs'>
              <input
                type='radio'
                name='tabset-article'
                id='ARTICLE'
                aria-controls='ARTICLE'
                defaultChecked
              />
              <input
                type='radio'
                name='tabset-article'
                id='SETTINGS'
                aria-controls='SETTINGS'
              />
              <label
                className='tab ARTICLE'
                htmlFor='ARTICLE'
                data-group='tabset-article'
              >
                {title}
              </label>
              <label
                className='tab SETTINGS'
                htmlFor='SETTINGS'
                data-group='tabset-article'
              >
                SETTINGS
              </label>
              <div className='border-holder'></div>
            </div>
            <div className='tab-panels'>
              <section id='ARTICLE' className='tab-panel'>
                <Article title={title} hideTitle={hideTitle}>
                  {children}
                </Article>
              </section>
              <section id='SETTINGS' className='tab-panel'>
                <Settings />
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Style
