import React from 'react'
import { BlueFrame } from './Logo'
import './Brand.css'

interface LogoContainerProps {}

const LogoContainer: React.FunctionComponent<LogoContainerProps> = () => {
  return (
    <div className='brand'>
      <div className='logo-container'>
        <BlueFrame />
      </div>
      <div className='slogan animate__animated animate__delay-2s animate__fadeIn'>
        Hacks, tricks, frontier W3C features and drafts.
      </div>
    </div>
  )
}

export default LogoContainer
