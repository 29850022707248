import React, { useEffect, useState } from 'react';
import './Logo.css';

export const BlueFrame: React.FC<{}> = () => {
  const [logoAnimTrigger, setLogoAnimTrigger] = useState(false);
  useEffect(() => {
    setTimeout(() => setLogoAnimTrigger(true), 1000);
  }, []);

  return (
    <div className={`logo ${logoAnimTrigger ? 'expand' : ''}`}>
      <div className='left'>
        <div className='text'>css.sh</div>
        <div className='frame height15'></div>
        <div className='frame height14'></div>
        <div className='frame height13'></div>
        <div className='frame height12'></div>
        <div className='frame height11'></div>
        <div className='frame height10'></div>
        <div className='frame height9'></div>
        <div className='frame height8'></div>
        <div className='frame height7'></div>
        <div className='frame height6'></div>
        <div className='frame height5'></div>
        <div className='frame height4'></div>
        <div className='frame height3'></div>
        <div className='frame height2'></div>
        <div className='frame height1'></div>
      </div>
      <div className='right'>
        <div className='text hide'>
          <span>css.</span>
          <span className='strong'>s</span>
          <span className='normal'>uper</span>
          <span className='strong'>h</span>
          <span className='normal'>acked</span>
        </div>
      </div>
    </div>
  );
};
