import './Theme.css'
import { useState, useEffect } from 'react'

interface ThemeProps {}

const Theme: React.FC<ThemeProps> = () => {
  const [darkMode, setDarkMode] = useState(false)
  const [theme, setTheme] = useState('light')

  useEffect(() => {
    if (window.matchMedia) {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setDarkMode(true)
      }
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', (event) => {
          setDarkMode(event.matches)
          console.log('darkMode', event.matches)
        })
    }
  }, [])

  useEffect(() => {
    // :has() selector polyfill
    const input = document.querySelector('input[name="theme"]:checked')
    let id = input?.id
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      id = 'dark'
    }
    if (id) {
      document.body.classList.add(id)
    }
  }, [])
  useEffect(() => {
    // :has() selector polyfill
    // handle click tab
    const labelList = Array.from(document.querySelectorAll('label.theme'))
    labelList.forEach((el) => {
      el.addEventListener('click', (e: any) => {
        const htmlFor = e.target?.htmlFor

        if (htmlFor) {
          document.body.classList.remove('dark', 'light', 'green', 'blue')
          document.body.classList.add(htmlFor)
        }
      })
    })
  }, [])

  const changeMetaTheme = () => {
    const color = getComputedStyle(document.documentElement).getPropertyValue(
      '--back-color'
    )
    const themeColor = document.querySelector('meta[name="theme-color"]')
    themeColor?.setAttribute('content', color)
  }

  useEffect(() => {
    changeMetaTheme()
  }, [darkMode, theme])

  return (
    <div className='themes'>
      <input
        onChange={(e) => setTheme(e.target.id)}
        type='radio'
        id='light'
        name='theme'
      />
      <input
        onChange={(e) => setTheme(e.target.id)}
        type='radio'
        id='dark'
        name='theme'
      />
      <input
        onChange={(e) => setTheme(e.target.id)}
        type='radio'
        id='green'
        name='theme'
      />
      <input
        onChange={(e) => setTheme(e.target.id)}
        type='radio'
        id='blue'
        name='theme'
      />
      <label className='theme' htmlFor='light'></label>
      <label className='theme' htmlFor='dark'></label>
      <label className='theme' htmlFor='green'></label>
      <label className='theme' htmlFor='blue'>
        ☁️
      </label>
      <div className='vars'></div>
    </div>
  )
}

export default Theme
