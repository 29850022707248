import React, { useState } from 'react'
import './Article.css'

interface ArticleProps {
  title?: string
  hideTitle?: boolean
  children: React.ReactNode
}

const Article: React.FunctionComponent<ArticleProps> = ({
  title,
  children,
  hideTitle = false,
}) => {
  const [openStatus, setOpenStatus] = useState(false)

  return (
    <div className={`article-container ${openStatus ? 'opened' : ''}`}>
      {!hideTitle && (
        <div className='article-title' onClick={() => setOpenStatus(true)}>
          {title}
        </div>
      )}
      <div className='article-content'>{children}</div>
    </div>
  )
}

export default Article
